import React from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './InsightsBlock.scss';

const InsightsBlock = (props) => {
  return (
    <section className="insights-block-container">
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-8 col-xl-7 col-md-12">
            <Placeholder name="jss-left" rendering={props?.rendering} />
          </div>
          <div className="col-lg-4 offset-lg-0 col-xl-4 offset-xl-1 col-md-12">
            <Placeholder name="jss-right" rendering={props?.rendering} />
          </div>
        </div>
      </div>
    </section>
  );
};
InsightsBlock.defaultProps = {
  fields: {},
  rendering: {
    placeholders: {},
  },
};

InsightsBlock.prototype = {
  fields: PropTypes.shape({}),
  rendering: PropTypes.shape({}),
};

export default InsightsBlock;
