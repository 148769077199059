import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import './forms.scss';
import { removeFocus } from '../../../../redux/actions/actions';
import { isNullorEmpty } from '../../../../utils/helperUtils';

/**
 *
 * @param {*} props - props
 * @returns {*} - action
 */
const CheckboxGroup = (props) => {
  const dispatch = useDispatch();
  const {
    options,
    name,
    selectedValues,
    selectHandler,
    cssClass,
    //totalCount,
  } = props;
  const [selected, setSelected] = useState(selectedValues);
  const focusId = props?.focusId ? props?.focusId : null;
  const checkBoxRef = useRef(null);
  useEffect(() => {
    setSelected(selectedValues);
    if (!isNullorEmpty(checkBoxRef) && !isNullorEmpty(checkBoxRef?.current))
      checkBoxRef.current.focus();
    // window.scrollTo(0, 0);
  }, [selectedValues]);

  /**
   *@param {*} event - event
   *@param {*} option - option
   *@returns {*} - action
   */
  const handleSelect = (event, option) => {
    dispatch(removeFocus(false));
    if (event && event.target) {
      let selectedVals = selected;

      if (event.target.checked) {
        if (!isOptionSelected(option)) {
          selectedVals.push(option);
        }
        console.log(selectedVals, '=======1');
      } else {
        selectedVals = selectedVals.filter(
          (val) =>
            !(
              val.id === option.id &&
              (decodeURIComponent(val?.value).replace(/[^A-Z0-9]/gi, '') ===
                option?.value?.replace(/[^A-Z0-9]/gi, '') ||
                decodeURIComponent(val?.text).replace(/[^A-Z0-9]/gi, '') ===
                  option?.text?.replace(/[^A-Z0-9]/gi, ''))
            )
        );
      }
      console.log(selectedVals, '=======2');
      setSelected(selectedVals);

      if (selectHandler) {
        const selectOptionKey = event.target.checked ? option.id : null;
        // Returns event and selected value
        console.log(event, selectedVals, selectOptionKey, 'selectOptionKey=====');
        selectHandler(event, selectedVals, selectOptionKey);
      }
    }
  };

  /**
   *@param {*} option - option
   *@returns {*} - action
   */
  const isOptionSelected = (option) =>
    selected.find(
      (val) =>
        val.id === option.id &&
        (decodeURIComponent(val?.value).replace(/[^A-Z0-9]/gi, '') ===
          option?.value?.replace(/[^A-Z0-9]/gi, '') ||
          decodeURIComponent(val?.text).replace(/[^A-Z0-9]/gi, '') ===
            option?.text?.replace(/[^A-Z0-9]/gi, ''))
    );
  return (
    <ul className={`form-checkbox-group checklist ${cssClass}${name}`}>
      {options.map((option) => {
        const { id, text, value, disabled, count = '' } = option || {};
        const val = value || text;
        return (
          <li className="checklist__item" key={id}>
            <div className="form-block checkbox checkbox--toggle">
              <input
                className="form-check-input"
                type="checkbox"
                ref={
                  !isNullorEmpty(focusId) && focusId?.current === id
                    ? checkBoxRef
                    : null
                }
                name={name}
                id={id}
                value={val}
                checked={isOptionSelected(option)}
                onChange={(event) => handleSelect(event, option)}
                disabled={disabled}
              />
              <label className="form-check-label" htmlFor={id}>
                {text}
              </label>
              {count && count !== '' ? <span>{count}</span> : null}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

CheckboxGroup.defaultProps = {
  options: [],
  selectedValues: [],
  selectHandler: () => {},
  cssClass: '',
};

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Field identifier  */
      id: PropTypes.string.isRequired,

      /** Label text */
      text: PropTypes.string.isRequired,
      value: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
  name: PropTypes.string.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.shape({})),
  selectHandler: PropTypes.func,
  cssClass: PropTypes.string,
};

export default CheckboxGroup;
