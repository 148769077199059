import React from 'react';

const FullScreen = () => (
  <svg
    width="16"
    height="16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    clipRule="evenodd"
    viewBox="0 0 512 469.562"
  >
    <path d="M352.991 29.305c-8.086 0-14.653-6.567-14.653-14.653S344.905 0 352.991 0h130.904c8.086 0 14.653 6.566 14.653 14.652V147.79c0 8.087-6.567 14.653-14.653 14.653s-14.652-6.566-14.652-14.653V50.055L367.465 152.221c-5.687 5.717-14.942 5.742-20.659.056-5.717-5.687-5.741-14.942-.055-20.659L448.677 29.305h-95.686zM29.305 159.015c0 8.086-6.566 14.652-14.653 14.652C6.566 173.667 0 167.101 0 159.015V28.105c0-8.087 6.566-14.653 14.652-14.653h133.139c8.086 0 14.652 6.566 14.652 14.653 0 8.085-6.566 14.652-14.652 14.652H50.056l102.165 101.778c5.717 5.686 5.742 14.942.056 20.659-5.686 5.716-14.942 5.741-20.658.055L29.305 63.323v95.692zm129.704 281.242c8.086 0 14.653 6.567 14.653 14.653s-6.567 14.652-14.653 14.652H28.105c-8.086 0-14.653-6.566-14.653-14.652V321.778c0-8.086 6.567-14.652 14.653-14.652s14.652 6.566 14.652 14.652v97.729l101.772-102.16c5.686-5.717 14.942-5.742 20.659-.055 5.717 5.686 5.741 14.941.055 20.658L63.323 440.257h95.686zm323.686-129.704c0-8.086 6.566-14.652 14.653-14.652 8.086 0 14.652 6.566 14.652 14.652v130.911c0 8.086-6.566 14.652-14.652 14.652H364.209c-8.086 0-14.652-6.566-14.652-14.652 0-8.086 6.566-14.653 14.652-14.653h97.735L359.779 325.033c-5.717-5.686-5.742-14.941-.056-20.658 5.686-5.717 14.942-5.742 20.658-.056l102.314 101.927v-95.693z" />
  </svg>
);

export default FullScreen;
