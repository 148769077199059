/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import './SocialIconStyle.scss';
import { setAltUrlForImg, isNullorEmpty } from '../../../../utils/helperUtils';

/**
 * @description -  to copy to clipboard
 * @param {string} shareUrl - url to be copied
 * @param {function} setToasterVisibility - to set visibility
 * @returns {undefined} - no return
 */
const copyTask = (shareUrl, setToasterVisibility) => {
  setToasterVisibility(true);
  copy(shareUrl);
  setTimeout(function () {
    setToasterVisibility(false);
  }, 1500);
};
/**
 * @description - Social Icons Component.
 * @param {Object} param0 - Input Props.
 * @returns {Node} - Html template.
 */
const SocialIcon = (props) => {
  const {
    img,
    altText,
    tooltip,
    ctaUrl,
    displayName,
    parentFields,
    currentPageUrl,
    arialabel,
    utmQueryParam,
    t,
    setExpanded,
    socialShareNew
  } = props;
  let shareUrl;
  const [showToaster, setToasterVisibility] = useState(false);
  const [queryParam, setqueryParam] = useState('');
  useEffect(() => {
    setqueryParam(window.location);
  }, []);

  const queryParams = new URLSearchParams(queryParam.search);
  const utmqueryParams = new URLSearchParams(utmQueryParam);
  if (String(queryParams) !== '' && String(queryParams).indexOf('utm') > -1) {
    if (!isNullorEmpty(utmqueryParams.get('utm_source')))
      queryParams.set('utm_source', utmqueryParams.get('utm_source'));
    if (!isNullorEmpty(utmqueryParams.get('utm_campaign')))
      queryParams.set('utm_campaign', utmqueryParams.get('utm_campaign'));
    if (!isNullorEmpty(utmqueryParams.get('utm_medium')))
      queryParams.set('utm_medium', utmqueryParams.get('utm_medium'));
    if (!isNullorEmpty(utmqueryParams.get('utm_term')))
      queryParams.set('utm_term', utmqueryParams.get('utm_term'));
    if (!isNullorEmpty(utmqueryParams.get('utm_content')))
      queryParams.set('utm_content', utmqueryParams.get('utm_content'));
    queryParams.delete('referer');
  }
  switch (displayName) {
    case 'Copy':
      if (String(queryParams) !== '' && String(queryParams).indexOf('utm') > -1) {
        shareUrl =
          queryParams.toString() !== ''
            ? ctaUrl +
              queryParam.protocol +
              '//' +
              queryParam.host +
              queryParam.pathname +
              '?' +
              queryParams
            : ctaUrl + currentPageUrl;
        // break;
      } else {
        shareUrl =
          utmQueryParam && utmQueryParam !== ''
            ? ctaUrl + encodeURIComponent(currentPageUrl + utmQueryParam)
            : ctaUrl + currentPageUrl;
        // break;
      }
      break;
    case 'Email':
      const mapObj = {
        '<headline>': parentFields?.headline?.value
          ? parentFields.headline.value
          : '',
        '<contentType>': parentFields?.contentType?.fields?.value
          ? parentFields.contentType.fields.value
          : '',
        '<topic>': parentFields?.topic?.fields?.value
          ? parentFields.topic.fields.value
          : '',
        '<subTopic>': parentFields?.subTopic?.fields?.value
          ? parentFields.subTopic.fields.value
          : '',
        '<articlepageLink>':
          String(queryParams).indexOf('utm') > -1
            ? encodeURIComponent(
                queryParam.protocol +
                  '//' +
                  queryParam.host +
                  queryParam.pathname +
                  '?' +
                  queryParams
              )
            : utmQueryParam
            ? currentPageUrl + encodeURIComponent(utmQueryParam)
            : currentPageUrl,
        '<subTitle>': parentFields?.subTitle?.value
          ? parentFields.subTitle.value
          : '',
        '<abstract>': parentFields?.abstract?.value
          ? parentFields.abstract.value
          : '',
      };

      const regexKeys = new RegExp(Object.keys(mapObj).join('|'), 'gi');
      shareUrl = ctaUrl?.replace(regexKeys, function (matched) {
        return mapObj[matched];
      });
      break;
    default:
      if (String(queryParams) !== '' && String(queryParams).indexOf('utm') > -1) {
        shareUrl =
          queryParams.toString() !== ''
            ? ctaUrl +
              encodeURIComponent(
                queryParam.protocol +
                  '//' +
                  queryParam.host +
                  queryParam.pathname +
                  '?' +
                  queryParams
              )
            : ctaUrl + currentPageUrl;
        break;
      } else {
        shareUrl =
          utmQueryParam && utmQueryParam !== ''
            ? ctaUrl + encodeURIComponent(currentPageUrl + utmQueryParam)
            : ctaUrl + currentPageUrl;
        break;
      }
  }

  /**
   * @description - Function to handle on enter key press.
   * @param {Object} e - Event.
   * @returns {undefined}
   */
  const handleOnEnter = (e) => {
    if (e.keyCode === 13) {
      window.open(shareUrl, '', 'width=600, height=400');
    }
  };

  const handleOnCopyEnter = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      copyTask(shareUrl, setToasterVisibility);
    }
  };

  switch (displayName) {
    case 'Email':
      return (
        <a
          href={shareUrl}
          className={`social-icon ${socialShareNew && "social-icon-circle"} `}
          target="_blank"
          rel="noopener noreferrer"
          title={tooltip}
          onClick={(e) => {
            if (props.setShareEvent) props.setShareEvent(true);
            e.stopPropagation();
          }}
        >
          <Image field={setAltUrlForImg(img, altText)} />
        </a>
      );
    case 'Copy Link':
      return (
        <>
          <div
            aria-label={props.setShareEvent ? 'Copy Job url' : 'Copy url'}
            className={`social-icon ${socialShareNew && "social-icon-circle"} `}
            rel="noopener noreferrer"
            title="Copy URL"
            onClick={(e) => {
              if (props.setShareEvent) props.setShareEvent(true);
              copyTask(shareUrl, setToasterVisibility);
              e.stopPropagation();
            }}
            tabIndex={0}
            role="link"           
            onKeyUp={handleOnCopyEnter}
            onBlur={() => setExpanded && setExpanded(false)}
          >
            <Image field={setAltUrlForImg(img, 'Copy URL icon')} />
          </div>
          {showToaster && !socialShareNew && (
            <span role="alert" className="custom-tooltip">
              {t('link-copied')}
            </span>
          )}
          {socialShareNew && showToaster && (
          <div className="alert alert-success social-share-new-alert">
            {t('link-copied')}
          </div>
          )}
        </>
      );

    default:
      return (
        <div
          className={`social-icon ${socialShareNew && "social-icon-circle"} `}
          rel="noopener noreferrer"
          title="Open in a new window/tab"
          role="link"
          aria-label={arialabel || altText}
          tabIndex={0}
          onKeyUp={handleOnEnter}
          onClick={(e) => {
            if (props.setShareEvent) props.setShareEvent(true);
            window.open(shareUrl, '', 'width=600, height=400');
            e.stopPropagation();
          }}
          style={{ cursor: 'pointer' }}
        >
          <Image field={setAltUrlForImg(img, altText)} />
        </div>
      );
  }
};

SocialIcon.defaultProps = {
  img: '',
  altText: '',
  tooltip: '',
  ctaUrl: '#',
  ctaUrl: '',
  displayName: '',
  currentPageUrl: '',
  parentFields: {},
  utmQueryParam: '',
  t: () => {},
  arialabel: '',
  socialShareNew: false,
};

SocialIcon.propTypes = {
  img: PropTypes.string.isRequired,
  altText: PropTypes.string,
  tooltip: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  currentPageUrl: PropTypes.string.isRequired,
  parentFields: PropTypes.shape({}),
  utmQueryParam: PropTypes.string.isRequired,
  t: PropTypes.func,
  arialabel: PropTypes.string,
  socialShareNew: PropTypes.bool,
};

export default withTranslation()(SocialIcon);
