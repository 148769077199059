import React from 'react';

const SortIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <line x1="10" y1="3.4" x2="24" y2="3.4" stroke="#282A32" strokeWidth="1.2" />
    <path
      d="M4.92426 2.57574C4.68995 2.34142 4.31005 2.34142 4.07574 2.57574L0.257358 6.39411C0.023044 6.62843 0.023044 7.00833 0.257358 7.24264C0.491673 7.47696 0.871572 7.47696 1.10589 7.24264L4.5 3.84853L7.89411 7.24264C8.12843 7.47696 8.50833 7.47696 8.74264 7.24264C8.97695 7.00833 8.97695 6.62843 8.74264 6.39411L4.92426 2.57574ZM5.1 20L5.1 3L3.9 3L3.9 20L5.1 20Z"
      fill="#282A32"
    />
    <line x1="10" y1="19.4" x2="24" y2="19.4" stroke="#282A32" strokeWidth="1.2" />
    <line x1="24" y1="11.6" x2="10" y2="11.6" stroke="#282A32" strokeWidth="1.2" />
  </svg>
);

export default SortIcon;
