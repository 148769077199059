import React from 'react';
import PropTypes from 'prop-types';
import { DATE_FORMATS } from '../../../utils/enums';
import moment from 'moment';
import { mediaApi } from '@sitecore-jss/sitecore-jss';

const PageBannerImageInsights = ({ fields }) => {
  const { data } = fields;
  const {
    publicationDate,
    topic,
    bannerImageDesktop,
    bannerImageMobile,
    bannerImageDesktopAlt,
  } = data;

  const nullishDate = '0001-01-01T00:00:00Z';
  const isValidDate = nullishDate === publicationDate?.value;
  const dateFormat = DATE_FORMATS.DEFAULT_NEW_US_SMALL;

  return (
    <div className="page-banner-image-insights container-xl">
      <div className="page-banner-content">
        <h1 className="page-banner-title-insights">{data?.title}</h1>
        <p className="page-banner-topics-insights">
          <img src={topic?.topicsIconDesktop} alt="icon" className="topics-icon" />
          <span> {topic?.topicsTitle} </span>
          <span>
            {' '}
            &#8226; {!isValidDate &&
              `${moment(publicationDate).format(dateFormat)}`}{' '}
            {/* &#8226; */}
          </span>
        </p>
        <picture className="page-banner-bgimage-insights">
          <source
            media="(max-width: 767px)"
            srcSet={mediaApi.updateImageUrl(bannerImageMobile)}
          />
          <img
            src={mediaApi.updateImageUrl(bannerImageDesktop)}
            alt={bannerImageDesktopAlt}
          />
        </picture>
      </div>
    </div>
  );
};

PageBannerImageInsights.defaultProps = {
  fields: {
    data: {
      title: '',
      publicationDate: '',
      bannerImageDesktop: '',
      bannerImageDesktopAlt: '',
      bannerImageMobile: '',
      bannerImageMobileAlt: '',
      topic: {
        topicsTitle: '',
        topicsIconDesktop: '',
        topicsIconDesktopAlt: '',
        topicsIconMobile: '',
        topicsIconMobileAlt: '',
      },
    },
  },
};

PageBannerImageInsights.propTypes = {
  fields: PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.string,
      publicationDate: PropTypes.string,
      bannerImageDesktop: PropTypes.string,
      bannerImageDesktopAlt: PropTypes.string,
      bannerImageMobile: PropTypes.string,
      bannerImageMobileAlt: PropTypes.string,
      topic: PropTypes.shape({
        topicsTitle: PropTypes.string,
        topicsIconDesktop: PropTypes.string,
        topicsIconDesktopAlt: PropTypes.string,
        topicsIconMobile: PropTypes.string,
        topicsIconMobileAlt: PropTypes.string,
      }),
    }),
  }),
};

export default PageBannerImageInsights;
