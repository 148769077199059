import React from 'react';

const RightChevronIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
  >
    <path
      d="M5 5.16667L7.91667 8.08333L5 11"
      stroke="#939498"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RightChevronIcon;
