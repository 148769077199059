import React from 'react';
import PropTypes from 'prop-types';
import './StandardLargeCard.scss';
import { mediaApi, RichText } from '@sitecore-jss/sitecore-jss-react';
import { getBGImage, getImg } from '../../../utils/helperUtils';
import { useMobile } from '../../../hooks/useMobile';
import ButtonLinkInsights from '../globals/link/ButtonLinkInsights';

const StandardLargeCard = ({ fields }) => {
  const isMobile = useMobile();
  const backgroundImage = (backgroundImageDesktop, backgroundImageMobile) =>
    getBGImage(
      backgroundImageDesktop,
      backgroundImageMobile,
      backgroundImageMobile !== '' && isMobile
    );

  return (
    <section>
      <div className="std-large-card-container">
        {fields?.selectItems?.map((item) => (
          <div
            key={item?.id}
            className="std-large-card-image"
            aria-hidden="true"
            style={{
              //backgroundImage: `${item?.fields?.backgroundColor?.fields?.colorCode?.value}, url(https://picsum.photos/id/1043/800/600)`, // for future use and test condition
              backgroundImage: `${
                item?.fields?.backgroundColor?.fields?.colorCode?.value
              }, url(${mediaApi.updateImageUrl(
                getImg(
                  backgroundImage(
                    item?.fields?.backgroundImageDesktop?.value?.src,
                    item?.fields?.backgroundImageMobile?.value?.src
                  )
                )
              )})`,
            }}
          >
            <div className="std-large-card-content-wrapper">
              <h1 className="std-large-card-title">{item?.fields?.title?.value}</h1>
              <RichText field={item?.fields?.content} />
              <div className="std-large-card-button">
                <ButtonLinkInsights
                  ctaBackgroundColor={
                    item?.fields?.ctaBackgroundColor?.fields?.colorCode?.value
                  }
                  buttonRadius={
                    item?.fields?.ctaBackgroundColor?.fields?.buttonRadius?.value
                  }
                  mouseHoverColor={
                    item?.fields?.ctaBackgroundColor?.fields?.mouseHoverColor?.value
                  }
                  ctaFontColour={
                    item?.fields?.ctaBackgroundColor?.fields?.fontColor?.value
                  }
                  ctaText={item?.fields?.ctaText?.value}
                  ctaUrl={item?.fields?.ctaLink?.value?.href}
                  arrow
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

StandardLargeCard.defaultProps = {
  fields: {
    selectItems: [],
  },
};

StandardLargeCard.propTypes = {
  fields: PropTypes.shape({
    selectItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string,
        name: PropTypes.string,
        displayName: PropTypes.string,
        fields: PropTypes.shape({
          title: PropTypes.shape({
            value: PropTypes.string,
          }),
          content: PropTypes.shape({
            value: PropTypes.string,
          }),
          backgroundColor: PropTypes.shape({
            id: PropTypes.string,
            url: PropTypes.string,
            name: PropTypes.string,
            displayName: PropTypes.string,
            fields: PropTypes.shape({
              colorCode: PropTypes.shape({
                value: PropTypes.string,
              }),
              fontColor: PropTypes.shape({
                value: PropTypes.string,
              }),
              style: PropTypes.shape({
                value: PropTypes.string,
              }),
            }),
          }),
          backgroundImageMobile: PropTypes.shape({
            value: PropTypes.shape({
              src: PropTypes.string,
              alt: PropTypes.string,
              width: PropTypes.string,
              height: PropTypes.string,
            }),
          }),
          backgroundImageDesktop: PropTypes.shape({
            value: PropTypes.shape({
              src: PropTypes.string,
              alt: PropTypes.string,
              width: PropTypes.string,
              height: PropTypes.string,
            }),
          }),
          ctaBackgroundColor: PropTypes.shape({
            id: PropTypes.string,
            url: PropTypes.string,
            name: PropTypes.string,
            displayName: PropTypes.string,
            fields: PropTypes.shape({
              colorCode: PropTypes.shape({
                value: PropTypes.string,
              }),
              fontColor: PropTypes.shape({
                value: PropTypes.string,
              }),
              style: PropTypes.shape({
                value: PropTypes.string,
              }),
            }),
          }),
          ctaText: PropTypes.shape({
            value: PropTypes.string,
          }),
          ctaIcon: PropTypes.shape({
            value: PropTypes.shape({
              src: PropTypes.string,
              alt: PropTypes.string,
            }),
          }),
          ctaLink: PropTypes.shape({
            value: PropTypes.shape({
              text: PropTypes.string,
              anchor: PropTypes.string,
              linktype: PropTypes.string,
              class: PropTypes.string,
              title: PropTypes.string,
              target: PropTypes.string,
              querystring: PropTypes.string,
              id: PropTypes.string,
              href: PropTypes.string,
            }),
          }),
          showCTAIcon: PropTypes.shape({
            value: PropTypes.bool,
          }),
          ctaIconPosition: PropTypes.shape({
            id: PropTypes.string,
            url: PropTypes.string,
            name: PropTypes.string,
            displayName: PropTypes.string,
            fields: PropTypes.shape({
              text: PropTypes.shape({
                value: PropTypes.string,
              }),
            }),
          }),
        }),
      })
    ),
  }),
};

export default StandardLargeCard;
