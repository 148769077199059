import React from 'react';
import PropTypes from 'prop-types';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import './StoryCards.scss';

const StoryCards = ({ fields }) => {
  const { icon, items, Text, componentBackground, backgroundColor } = fields;
  const [
    colorStop1,
    colorStop2,
    colorStop3,
  ] = backgroundColor?.fields?.colorCode?.value.split(',');
  return (
    <div
      className="story-insights container-xl"
      style={{
        background: `${componentBackground?.fields?.colorCode?.value}`,
      }}
    >
      <div className="row">
        <div className="col-12">
          <h3
            className="title"
            style={{
              background: `linear-gradient(90deg, ${colorStop1} 0%, ${colorStop2} 46.87%, ${colorStop3} 100%)`,
            }}
          >
            <img
              src={mediaApi.updateImageUrl(icon?.value?.src)}
              alt={icon?.value?.alt || 'icon'}
              className="icon"
            />
            {Text.value}
          </h3>
          <ul className="items-container">
            {items.map((item, index) => (
              <li key={index} className="item">
                <strong>{item?.fields?.title?.value}:</strong>{' '}
                {item?.fields?.content?.value}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

StoryCards.defaultProps = {
  fields: {
    icon: { value: '' },
    items: [],
    Text: { value: '' },
    backgroundColor: { value: '' },
    componentBackground: { value: '' },
  },
};

StoryCards.propTypes = {
  fields: PropTypes.shape({
    icon: PropTypes.shape({ value: PropTypes.string.isRequired }),
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    Text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    backgroundColor: PropTypes.shape({ value: PropTypes.string.isRequired }),
    componentBackground: PropTypes.shape({ value: PropTypes.string.isRequired }),
  }),
};

export default StoryCards;
