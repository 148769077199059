/* eslint-disable complexity */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SocialIcon from '../globals/icons/SocialIcon';
import './SocialShare.scss';
import SocialShareIcon from '../globals/svgicons/SocialShareIcon';

const SocialShare = ({ fields, setShareEvent, setExpanded }) => {
  const [isMobile, setIsOpen] = useState(false);
  const [currentPageUrl, setCurrentPageUrl] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentPageUrl(window?.location?.href);
    }
  }, []);

  const toggleShare = () => {
    setIsOpen(!isMobile);
  };

  return (
    <div className={`social-share-container`}>
      <div className={`social-share ${isMobile ? 'mobile-social-share' : ''}`}>
        {fields?.socialSelect.map((social, index) => (
          <Fragment key={index}>
            <SocialIcon
              img={social?.fields?.iconDesktop?.value}
              altText={`${social?.fields?.name?.value} icon`}
              tooltip={social?.fields?.name?.value}
              ctaUrl={social?.fields?.url?.value?.url}
              displayName={social?.fields?.name?.value}
              arialabel={social?.fields?.name?.value}
              parentFields={social?.fields}
              currentPageUrl={currentPageUrl}
              utmQueryParam={social?.fields?.utmQueryparams?.value}
              setShareEvent={setShareEvent}
              setExpanded={setExpanded}
              socialShareNew={true}
            />
          </Fragment>
        ))}
      </div>
      <div className="social-share-button">
        <button className="share-toggle" onClick={toggleShare}>
          <SocialShareIcon />
        </button>
      </div>
    </div>
  );
};

SocialShare.defaultProps = {
  fields: {
    socialSelect: [
      {
        id: '',
        url: '',
        name: '',
        displayName: '',
        fields: {
          url: {
            value: {
              href: '',
              linktype: '',
              url: '',
              anchor: '',
              target: '',
            },
          },
          iconDesktop: {
            value: {
              src: '',
              alt: '',
            },
          },
          name: {
            value: '',
          },
          iconMobile: {
            value: {
              src: '',
              alt: '',
            },
          },
          utmQueryparams: {
            value: '',
          },
        },
      },
    ],
  },
  setShareEvent: () => {},
  setExpanded: () => {},
};

SocialShare.propTypes = {
  fields: PropTypes.shape({
    socialSelect: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string,
        name: PropTypes.string,
        displayName: PropTypes.string,
        fields: PropTypes.shape({
          url: PropTypes.shape({
            value: PropTypes.shape({
              href: PropTypes.string,
              linktype: PropTypes.string,
              url: PropTypes.string,
              anchor: PropTypes.string,
              target: PropTypes.string,
            }),
          }),
          iconDesktop: PropTypes.shape({
            value: PropTypes.shape({
              src: PropTypes.string,
              alt: PropTypes.string,
            }),
          }),
          name: PropTypes.shape({
            value: PropTypes.string,
          }),
          iconMobile: PropTypes.shape({
            value: PropTypes.shape({
              src: PropTypes.string,
              alt: PropTypes.string,
            }),
          }),
          utmQueryparams: PropTypes.shape({
            value: PropTypes.string,
          }),
        }),
      })
    ),
  }),
  setShareEvent: PropTypes.func,
  setExpanded: PropTypes.func,
};

export default SocialShare;
