import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchFiltersPanel from './SearchFilterPanel';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import filterIcon from '../../../assets/images/Insights/FilterIcon.png';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import FilterPill from './FilterPill';
import './InsightsSearchFilter.scss';

/**
 * @description - Filter component for jobs.
 * @param {object} param0 - Filter params.
 * @returns {Node} - Filter elements.
 */
const InsightsSearchFilter = ({
  t,
  InsightsSearchFilters,
  filteredData,
  checkLoaderMobile,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [showOverlay, setShowOverlay] = useState(false);
  const [filterPillCount, setFilterPillCount] = useState(0);

  const clearAllFilters = () => {
    history.replace({
      pathname: location?.pathname,
      search: String(decodeURIComponent('')),
    });
  };

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
    checkLoaderMobile(!showOverlay);
  };

  const handleFilterPillCountChange = (count) => {
    setFilterPillCount(count);
  };

  return (
    <Fragment>
      {InsightsSearchFilters && (
        <div className="search-filters-global search-filters--active">
          <div className="filter-block active">
            <div className="filter-block-inner">
              <div
                className="icon-filters"
                onClick={toggleOverlay}
                role="button"
                tabIndex="0"
                aria-hidden="true"
              >
                <img src={filterIcon} alt="filter icon" />
                <span className="filter-count">{filterPillCount}</span>
              </div>
              <div className="text desktop-only">Filter</div>
            </div>
            <div
              className="clear-all desktop-only"
              role="button"
              onClick={clearAllFilters}
              onKeyDown={clearAllFilters}
              tabIndex="0"
            >
              <div className="text">Clear All</div>
            </div>
          </div>
          <div className="search-filters-panel-global desktop-only">
            <div className="container">
              <div className="job-search-pills">
                <FilterPill
                  data={filteredData}
                  onFilterPillCountChange={handleFilterPillCountChange}
                />
              </div>

              <SearchFiltersPanel
                setFilterActive={true}
                t={t}
                filters={InsightsSearchFilters}
              />
            </div>
          </div>
        </div>
      )}
      {showOverlay && (
        <div className="search-filters">
          <div className="overlay-custom">
            <div className="close-overlay">
              <div className="overlay-title">All Filters</div>
              <div
                className="clear-all"
                role="button"
                onClick={clearAllFilters}
                onKeyDown={clearAllFilters}
                tabIndex="0"
              >
                <div className="text">Clear all</div>
              </div>
            </div>
            <div className="overlay-content">
              <div className="job-search-pills">
                <FilterPill
                  data={filteredData}
                  onFilterPillCountChange={handleFilterPillCountChange}
                />
              </div>
              <SearchFiltersPanel
                setFilterActive={true}
                t={t}
                filters={InsightsSearchFilters}
              />
            </div>
            <div className="overlay-buttons">
              <button
                className="cancel-button"
                aria-hidden="true"
                onClick={toggleOverlay}
              >
                Close
              </button>
              <button
                className="apply-button"
                aria-hidden="true"
                onClick={toggleOverlay}
              >
                See Results
              </button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

InsightsSearchFilter.propTypes = {
  filter: PropTypes.string,
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
};

InsightsSearchFilter.defaultProps = {
  filter: '',
  fields: {},
};

export default withSitecoreContext()(withTranslation()(InsightsSearchFilter));
