import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {
  isDataExists,
  isNullorEmpty,
  isObjNotEmpty,
  parseFilterData,
  setIdsParamAtLast,
} from '../../../utils/helperUtils';
import CheckboxGroup from '../../core/globals/forms/CheckboxGroup';
import SearchAccordion from './Accordion';
import {
  getFilterArray,
  getFormattedArray,
} from '../../../factory/jobSearchFilterFactory';
import { DEVICE } from '../../../constants';
import { CollapseFilterContext } from '../../business/Contexts';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
// import { toJson } from 'really-relaxed-json';
import './InsightsSearchFilter.scss';

/**
 * @description - Filter component for jobs.
 * @param {object} param0 - Filter params.
 * @returns {Node} - Filter elements.
 */
const getFormattedvalues = (values) => {
  return getFormattedArray(values || []);
};

const SearchFilterPanel = ({
  filters,
  sortOption,
  topics,
  brands,
  industry,
  events,
  removeFocus,
  type,
  //filterpills,
}) => {
  const location = useLocation();

  const [selectedFilters, setSelectedFilters] = useState({});
  const params = new URLSearchParams(location?.search);
  const sortby = useContext(CollapseFilterContext) || {};
  const filter = typeof window !== 'undefined' ? parseFilterData() : undefined;
  const filterObj = filter || {};
  const targetInput = useRef(null);
  const focusId = useRef(null);
  const history = useHistory();
  const newFilter = {};
  const [isMobView, setIsMobView] = useState(false);
  useEffect(() => {
    const isMobWidth = window.innerWidth <= DEVICE.TABLET.WIDTH;
    // initial rendering.
    if (isMobView !== isMobWidth) {
      setIsMobView(!isMobView);
    }
  }, []);
  useEffect(() => {
    const filterObj = parseFilterData() || {};
    setSelectedFilters({
      topics: getFilterArray(filterObj?.topics || []),
      brands: getFilterArray(filterObj?.brands || []),
      industries: getFilterArray(filterObj?.industries || []),
      events: getFilterArray(filterObj?.events || []),
      type: getFilterArray(filterObj?.type || []),
    });
    if (targetInput.current !== null) {
      targetInput.current.focus();
    }

    if (removeFocus) focusId.current = null;
  }, [location]);
  if (!isNullorEmpty(filters)) {
    for (const [key] of Object?.entries(filters)) {
      filters[key]?.length > 0
        ? (newFilter[key] = filters[key])
        : (filters[key] = '');
    }
  }

  const onFilterChange = (e, selectedVal) => {
    if (!isNullorEmpty(e.target.id)) {
      focusId.current = e.target.id;
    } else focusId.current = null;
    const fieldName = e?.target?.name;
    params.delete(fieldName);

    const finalVal = getFormattedvalues(selectedVal);
    if (isDataExists(finalVal)) {
      filterObj[fieldName] = finalVal;
    } else {
      delete filterObj[fieldName];
    }
    filterObj['sf'] = fieldName;
    if (isObjNotEmpty(filterObj)) {
      const ids = {};
      // console.log(filterObj, '=======1');
      Object.keys(filterObj).forEach((filter) => {
        if (Array.isArray(filterObj[filter])) {
          ids[filter] = [];
          const temp = [];
          filterObj[filter].filter((type) => {
            temp.push(type['value']?.replace(/,/g, '').replace('&', 'andValue'));
            ids[filter].push(type['key']);
          });
          params.set(filter, temp.join(','));
        } else {
          params.set(filter, filterObj[filter]);
        }
      });
      if (isObjNotEmpty(ids))
        params.set('ids', JSON.stringify(ids).split('"').join(''));
    }
    //const currentPage = params.get('page') === null ? 1 : params.get('page');
    params.set('page', 1);
    setIdsParamAtLast(params);
    console.log(String(decodeURIComponent(params)), params, '===============ff12');
    history.replace({
      pathname: location?.pathname,
      search: String(decodeURIComponent(params)),
    });
  };

  const filterSort = (e) => {
    const searchTerm = e.target.value.trim().toLowerCase();
    const listItems = document.querySelectorAll('ul.topics li');

    listItems.forEach(function (item) {
      item.style.display = 'revert';

      if (!item.innerText.toLowerCase().includes(searchTerm)) {
        item.style.display = 'none';
      }
    });
  };
  const getFilterLayout = (item = {}) => {
    switch (item) {
      case 'topics':
        return isDataExists(filters?.topics) ? (
          <div className="">
            <input
              type="textbox"
              className="topicSearch"
              placeholder="Search topics"
              onKeyUp={filterSort}
            />
            <FieldInputCheckbox
              focusId={focusId}
              inputName="topics"
              options={getFilterArray(filters?.topics || [])}
              selectHandler={onFilterChange}
              selectedValues={selectedFilters?.topics}
            />
          </div>
        ) : null;
      case 'brands':
        return isDataExists(filters?.brands) ? (
          <div className="">
            <FieldInputCheckbox
              focusId={focusId}
              inputName="brands"
              options={getFilterArray(filters?.brands || [])}
              selectHandler={onFilterChange}
              selectedValues={selectedFilters?.brands}
            />
          </div>
        ) : null;
      case 'industries':
        return isDataExists(filters?.industries) ? (
          <div className="">
            <FieldInputCheckbox
              focusId={focusId}
              inputName="industries"
              options={getFilterArray(filters?.industries || [])}
              selectHandler={onFilterChange}
              selectedValues={selectedFilters?.industries}
            />
          </div>
        ) : null;
      case 'events':
        return isDataExists(filters?.events) ? (
          <div className="">
            <FieldInputCheckbox
              focusId={focusId}
              inputName="events"
              options={getFilterArray(filters?.events || [])}
              selectHandler={onFilterChange}
              selectedValues={selectedFilters?.events}
            />
          </div>
        ) : null;
      case 'type':
        return isDataExists(filters?.type) ? (
          <div className="">
            <FieldInputCheckbox
              focusId={focusId}
              inputName="type"
              options={getFilterArray(filters?.type || [])}
              selectHandler={onFilterChange}
              selectedValues={selectedFilters?.type}
            />
          </div>
        ) : null;
      default:
        return null;
    }
  };

  const FieldInputCheckbox = ({
    options,
    selectHandler,
    selectedValues,
    inputName,
    focusId,
  }) => {
    return (
      <div className="search__filter search__filter--checklist" role="group">
        <fieldset>
          <CheckboxGroup
            focusId={focusId}
            options={options}
            name={inputName}
            selectHandler={selectHandler}
            selectedValues={selectedValues}
          />
        </fieldset>
      </div>
    );
  };
  return (
    <Fragment>
      <div className="search-filters-panel">
        <div className="row search-filter-layout">
          <div role="list" className="search-accordion-section">
            {isDataExists(Object?.keys(newFilter)) && (
              <SearchAccordion
                allowMultipleOpen={!isMobView}
                data={newFilter && Object?.keys(newFilter)}
                focusId={focusId}
                filterContent={getFilterLayout}
                selected={selectedFilters && selectedFilters}
                sortOption={
                  sortby?.sortby ? sortby?.sortby : sortOption?.value || ''
                }
                defaultValues={{
                  topics,
                  brands,
                  industry,
                  events,
                  type,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

SearchFilterPanel.propTypes = {
  filter: PropTypes.string,
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
};

SearchFilterPanel.defaultProps = {
  filter: '',
  fields: {},
};

export default withSitecoreContext()(withTranslation()(SearchFilterPanel));
