import React, { useEffect, useRef, useState } from 'react'; // Import React and necessary hooks
import PropTypes from 'prop-types'; // Import PropTypes for type checking
import { Helmet } from 'react-helmet'; // Import Helmet for managing document head
import './PageBannerPDFInsights.scss'; // Import the CSS file for styling
import { mediaApi } from '@sitecore-jss/sitecore-jss';
import { DATE_FORMATS } from '../../../utils/enums';
import moment from 'moment';
import Download from '../globals/svgicons/Download';
import FullScreen from '../globals/svgicons/FullScreen';
import LeftArrowChervon from '../globals/svgicons/LeftArrowChervon';
import RightArrowChervon from '../globals/svgicons/RightArrowChervon';

const PageBannerPDFInsights = ({ fields }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const { data } = fields;

  const nullishDate = '0001-01-01T00:00:00Z';
  const isValidDate = nullishDate === data?.publicationDate?.value;
  const dateFormat = DATE_FORMATS.DEFAULT_NEW_US_SMALL;

  const canvasRef = useRef(null); // Create a ref for the canvas element
  const fullscreenRef = useRef(null);
  const pdfUrl = fields?.data?.file; // URL of the PDF to be loaded

  useEffect(() => {
    const loadPDFJS = () => {
      if (typeof window === 'undefined') return; // Ensure window is defined

      const { pdfjsLib } = window; // Access pdfjsLib from the window object

      pdfjsLib.GlobalWorkerOptions.workerSrc =
        '//mozilla.github.io/pdf.js/build/pdf.worker.mjs'; // Set the worker source for PDF.js

      let pdfDoc = null; // Variable to store the loaded PDF document
      let pageNum = 1; // Current page number
      let pageRendering = false; // Flag to check if a page is being rendered
      let pageNumPending = null; // Variable to store the next page number to be rendered
      const scale = 2; // Scale for rendering the PDF
      const canvas = canvasRef?.current; // Get the canvas element
      const ctx = canvas.getContext('2d'); // Get the 2D context of the canvas

      const renderPage = (num) => {
        pageRendering = true; // Set the rendering flag to true
        pdfDoc.getPage(num).then((page) => {
          // Get the page from the PDF document
          const viewport = page.getViewport({ scale }); // Get the viewport for the page with the specified scale
          canvas.height = viewport?.height; // Set the canvas height
          canvas.width = viewport?.width; // Set the canvas width

          const renderContext = {
            canvasContext: ctx,
            viewport,
          };
          const renderTask = page.render(renderContext); // Render the page on the canvas

          renderTask.promise.then(() => {
            pageRendering = false; // Set the rendering flag to false
            if (pageNumPending !== null) {
              // If there is a pending page to be rendered
              renderPage(pageNumPending); // Render the pending page
              pageNumPending = null; // Reset the pending page number
            }
          });
        });

        document.getElementById('page_num').textContent = num; // Update the current page number in the UI
      };

      const queueRenderPage = (num) => {
        if (pageRendering) {
          // If a page is currently being rendered
          pageNumPending = num; // Set the pending page number
        } else {
          renderPage(num); // Render the page immediately
        }
      };

      const onPrevPage = () => {
        if (pageNum <= 1) {
          // If the current page is the first page
          return;
        }
        pageNum--; // Decrement the page number
        queueRenderPage(pageNum); // Queue the rendering of the previous page
      };

      const onNextPage = () => {
        if (pageNum >= pdfDoc.numPages) {
          // If the current page is the last page
          return;
        }
        pageNum++; // Increment the page number
        queueRenderPage(pageNum); // Queue the rendering of the next page
      };

      const onHomePage = () => {
        pageNum = 1; // Set the page number to the first page
        queueRenderPage(pageNum); // Queue the rendering of the first page
      };

      document.getElementById('prev').addEventListener('click', onPrevPage); // Add event listener for the previous page button
      document.getElementById('next').addEventListener('click', onNextPage); // Add event listener for the next page button
      document.getElementById('home').addEventListener('click', onHomePage); // Add event listener for the home button

      pdfjsLib.getDocument(pdfUrl).promise.then((pdfDoc_) => {
        // Load the PDF document
        pdfDoc = pdfDoc_; // Store the loaded PDF document
        document.getElementById('page_count').textContent = pdfDoc?.numPages; // Update the total page count in the UI
        renderPage(pageNum); // Render the first page
      });
    };

    if (typeof window !== 'undefined' && window.pdfjsLib) {
      // If PDF.js is already loaded
      loadPDFJS(); // Load the PDF.js library
    } else if (typeof window !== 'undefined') {
      const script = document.createElement('script'); // Create a script element
      script.src = '//mozilla.github.io/pdf.js/build/pdf.mjs'; // Set the source of the script
      script.type = 'module'; // Set the type of the script
      script.onload = loadPDFJS; // Set the onload event to load PDF.js
      document.body.appendChild(script); // Append the script to the body
    }
  }, [pdfUrl]); // Dependency array to run the effect when pdfUrl changes

  const downloadPDF = () => {
    const link = document.createElement('a'); // Create a link element
    link.href = pdfUrl; // Set the href to the PDF URL
    link.download = `${data?.title}.pdf`; // Set the download attribute
    link.click(); // Trigger a click on the link to download the PDF
  };

  const toggleFullscreen = () => {
    if (typeof window === 'undefined') return; // Ensure window is defined

    const element = fullscreenRef.current;
    if (!isFullscreen) {
      const requestFullscreen =
        element.requestFullscreen ||
        element.mozRequestFullScreen ||
        element.webkitRequestFullscreen ||
        element.msRequestFullscreen;
      if (requestFullscreen) {
        requestFullscreen.call(element);
      }
    } else {
      const exitFullscreen =
        document.exitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen;
      if (exitFullscreen) {
        exitFullscreen.call(document);
      }
    }
    element.onfullscreenchange = () => {
      if (!document.fullscreenElement) {
        setIsFullscreen(false);
      }
    };
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div className="container-xl page-banner-pdf-insights">
      <div className="page-banner-content">
        <h1 className="page-banner-title-insights">{data?.title}</h1>
        <p className="page-banner-topics-insights">
          <img
            src={data?.topic?.topicsIconDesktop}
            alt="icon"
            className="topics-icon"
          />
          <span> {data?.topic?.topicsTitle} </span>
          <span>
            {' '}
            &#8226;{' '}
            {!isValidDate &&
              `${moment(data?.publicationDate).format(dateFormat)}`}{' '}
            {/* &#8226; */}
          </span>
        </p>
      </div>
      <div className="pdf-viewer-container" id="fullscreenRef" ref={fullscreenRef}>
        <Helmet>
          <script type="module">
            {`
              var url = '${mediaApi.updateImageUrl(pdfUrl)}';
              var { pdfjsLib } = window;
              pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.mjs';
            `}
          </script>
        </Helmet>
        <div className="pdf-container">
          <canvas
            id="the-canvas"
            ref={canvasRef}
            style={{ width: '100%', height: `${!isFullscreen ? 'auto' : '83vh'}` }}
          ></canvas>
          {/* Canvas element for rendering the PDF */}
        </div>
        <div className="controls">
          <div className="left-controls">
            <button id="prev" className="control-button">
              <LeftArrowChervon />
            </button>{' '}
            {/* Button for previous page */}
            <span className="page-info">
              Page: <span id="page_num"></span> / <span id="page_count"></span>
            </span>{' '}
            {/* Page info display */}
            <button id="next" className="control-button">
              <RightArrowChervon />
            </button>{' '}
            {/* Button for next page */}
            <button id="home" className="control-button home-pdf-button">
              Home
            </button>{' '}
            {/* Button for home page */}
          </div>
          <div className="right-controls">
            <button onClick={downloadPDF} className="control-button download-button">
              <Download />
              <span className="right-controls-text"> Download PDF </span>
            </button>{' '}
            {/* Button for downloading the PDF */}
            <button
              onClick={toggleFullscreen}
              className="control-button fullscreen-button"
            >
              <FullScreen />
              <span className="right-controls-text">
                {' '}
                {isFullscreen ? 'Exit Full Screen' : 'Full Screen'}{' '}
              </span>
            </button>{' '}
          </div>
        </div>
      </div>
    </div>
  );
};

PageBannerPDFInsights.defaultProps = {
  fields: {
    data: {
      title: '',
      publicationDate: '',
      topic: {
        topicsTitle: '',
        topicsIconDesktop: '',
        topicsIconDesktopAlt: '',
        topicsIconMobile: null,
        topicsIconMobileAlt: null,
      },
      file: '',
      fileId: '',
    },
  },
};

PageBannerPDFInsights.propTypes = {
  fields: PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.string,
      publicationDate: PropTypes.string,
      topic: PropTypes.shape({
        topicsTitle: PropTypes.string,
        topicsIconDesktop: PropTypes.string,
        topicsIconDesktopAlt: PropTypes.string,
        topicsIconMobile: PropTypes.string,
        topicsIconMobileAlt: PropTypes.string,
      }),
      file: PropTypes.string,
      fileId: PropTypes.string,
    }),
  }),
};

export default PageBannerPDFInsights;
