import colorVariables from '../styles/scss_variables/colorVariables.scss';

export const Colors = {
  ORANGE: colorVariables.color_orange,
  WHITE: colorVariables.color_white,
  BLUE: colorVariables.color_blue,
  GREY: colorVariables.white_faded,
  BLACK: colorVariables.black,
  DARK_BLUE: '',
  DARK_GREEN: '',
  GREEN: colorVariables.dark_green,
  LIGHT_BLUE: '',
  LIGHT_GREEN: '',
  RED: colorVariables.color_red,
  LIGHT: '',
  DARK: '',
};

export const authstates = {
  AUTHENTICATED: 'AUTHENTICATED',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  PROGRESS: 'PROGRESS',
};

export const SORTORDER = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
};

export const buildProfile = {
  EditPersonalInfo: 0,
  EditExperience: 1,
  EditExpertiseAndSkills: 2,
  EditEducation: 3,
  EditCertifications: 4,
};

export const DATE_FORMATS = {
  LOCALE: 'LL',
  DEFAULT_US: 'MMMM DD, YYYY',
  TIME_FULL_HR_MIN: 'hh:mma',
  TIME_HR_MIN: 'h:mm a ',
  LOCALE_SMALL: 'll',
  ROBOTS_FORMAT: 'YYYY-MM-DD',
  ROBOTS_FORMAT_REV: 'DD/MM/YYYY',
  DEFAULT_US_SMALL: 'MMM DD, YYYY',
  DEFAULT_NEW_US_SMALL: 'DD MMM YYYY',
};

export const DEFAULT_APPLICATION_COLUMNS = [
  'dateAdded',
  'position',
  'companyName',
  'employmentType',
  'status',
  'path',
];

export const countryCodesList =
  'de,no,se,dk,fi,is,ee,lv,lt,be,es,nl,lu,at,ie,ge,gr,va,fr,si,ch,li,pt,gb,cy,hu,mt,pl,sk,bg,ro,cr';

export const fileTypesAllowed = ['HTML', 'PDF', 'DOC', 'DOCX'];

export const buildProfileItems = {
  EditPersonalInfo: 'PersonalInfo',
  EditBasicInfo: 'PersonalInfo',
  EditExperience: 'Experience',
  EditEducation: 'Education',
  UploadResume: 'AttachmentDetails',
  EditExpertiseAndSkills: 'PersonalInfo',
  EditGamingSkills: 'PersonalInfo',
  EditCompetency: 'PersonalInfo',
  EditCoverLetters: 'AttachmentDetails',
  EditCertifications: 'PersonalInfo',
};

export const dashboardItems = {
  PersonalInfo: 'PersonalDetails',
  Experience: 'ExperienceDetails',
  Education: 'EducationDetails',
  Resume: 'PersonalDetails',
  ExpertiseAndSkills: 'PersonalDetails',
  Gaming: 'PersonalDetails',
  CoverLetters: 'PersonalDetails',
  MyDocuments: 'AttachmentDetails',
  Certifications: 'PersonalDetails',
};

export const jobApply = {
  PersonalInfo: 'PersonalDetails',
  Experience: 'ExperienceDetails',
  Education: 'EducationDetails',
  UploadResume: 'AttachmentDetails',
  Consent: 'PersonalDetails',
};

export const TYPES = {
  ROMA: 'ROMA',
};

export const searchFilterLayout = {
  radius: { xl: '6', lg: '6', md: '6', sm: '12' },
  industries: { xl: '6', lg: '6', md: '6', sm: '12' },
  cities: { xl: '6', lg: '6', md: '6', sm: '12' },
  salaryRange: { xl: '6', lg: '6', md: '6', sm: '12' },
  jobTypes: { xl: '3', lg: '3', md: '6', sm: '12' },
  specialization: { xl: '3', lg: '3', md: '6', sm: '12' },
  experienceLevel: { xl: '3', lg: '3', md: '6', sm: '12' },
  education: { xl: '3', lg: '3', md: '6', sm: '12' },
  employmentType: { xl: '3', lg: '3', md: '6', sm: '12' },
  language: { xl: '3', lg: '3', md: '6', sm: '12' },
  workingHours: { xl: '6', lg: '6', md: '6', sm: '12' },
  managementLevel: { xl: '6', lg: '6', md: '6', sm: '12' },
  jobOfferType: { xl: '6', lg: '6', md: '6', sm: '12' },
  contractType: { xl: '6', lg: '6', md: '6', sm: '12' },
};

export const searchFilterLayoutGlobal = {
  sortOrder: { xl: '12', lg: '12', md: '12', sm: '12' },
  radius: { xl: '12', lg: '12', md: '12', sm: '12' },
  industries: { xl: '12', lg: '12', md: '12', sm: '12' },
  cities: { xl: '12', lg: '12', md: '12', sm: '12' },
  salaryRange: { xl: '12', lg: '12', md: '12', sm: '12' },
  salaryRangeLevels: { xl: '12', lg: '12', md: '12', sm: '12' },
  jobTypes: { xl: '12', lg: '12', md: '12', sm: '12' },
  specialization: { xl: '12', lg: '12', md: '12', sm: '12' },
  experienceLevel: { xl: '12', lg: '12', md: '12', sm: '12' },
  education: { xl: '12', lg: '12', md: '12', sm: '12' },
  employmentType: { xl: '12', lg: '12', md: '12', sm: '12' },
  language: { xl: '12', lg: '12', md: '12', sm: '12' },
  workingHours: { xl: '12', lg: '12', md: '12', sm: '12' },
  managementLevel: { xl: '12', lg: '12', md: '12', sm: '12' },
  jobOfferType: { xl: '12', lg: '12', md: '12', sm: '12' },
  contractType: { xl: '12', lg: '12', md: '12', sm: '12' },
  scheduleType: { xl: '12', lg: '12', md: '12', sm: '12' },
  showDisabilityIcon: { xl: '12', lg: '12', md: '12', sm: '12' },
};

export const htmlHexCodes = {
  UP: {
    value: '\u2191',
  },
  DOWN: {
    value: '\u2193',
  },
  B: {
    value: '\u0042',
  },
  K: {
    value: '\u004B',
  },
  M: {
    value: '\u004D',
  },
  '%': {
    value: '\u0025',
  },
};

export const variantClasses = {
  CALLOUTBAR: {
    FOOTER: 'footer',
    STANDARD: 'standard',
    LOGO: 'standard logo',
    UTILITY: 'utility',
    CONTACT: 'contact',
    CTA: {
      FOOTER: 'PRIMARYORANGE',
      LOGO: 'PRIMARYBLUE',
      STANDARD: 'PRIMARYBLUE',
    },
    COL: {
      FOOTER: 'col-lg-6',
      LOGO: 'col-md-10 offset-md-1',
      STANDARD: 'col-md-10 offset-md-1',
      UTILITY: 'col-lg-12',
    },
  },
  CARD: {
    ARTICLE: {
      FEATURED: 'card-article__featured',
      CASESTUDY: 'card-article__case-study',
      CTA: 'card-article__cta',
    },
  },
  CARDBLOCKS: {
    STAT: 'card-block__stat',
    EXPERTISE: 'card-block__expertise',
    OFFERINGS: 'card-block__offerings',
    INFO: 'card-block__info',
    RESOURCES: 'card-block__resources',
  },
  CARDBLOCKHEADER: {
    ROW: {
      ACCORDION: 'row',
      EXPERTISE: 'row justify-content-center text-center',
      INFO: 'row justify-content-center text-center',
      OFFERINGS: 'row justify-content-center text-center',
      RESOURCES: 'row',
      STAT: 'row',
      SUBNAV: 'row navbox-header',
      HYPERLINKS: 'row justify-content-center text-center',
    },
    COL: {
      ACCORDION: 'col-lg-12 offset-lg-0',
      EXPERTISE: 'col-lg-8',
      INFO: 'col-lg-8',
      OFFERINGS: 'col-lg-10',
      RESOURCES: 'col',
      STAT: 'col-lg-8 offset-lg-2',
      SUBNAV: 'col-lg-10 offset-lg-1',
      HYPERLINKS: ' col-lg-10',
    },
  },
  HOMEBLOCKS: {
    ALERT: 'home-block__alert',
  },
  FOOTERDISCLAIMER: {
    TextBlock: 'col-xl-4 col-lg-4 col-sm-12 copyright',
    SiteFooterSocial: 'col-xl-4 col-lg-5 col-sm-12 social-follow',
    ShowCountryLinksManpower: 'col-xl-4 col-lg-3 col-sm-12 footer-lang-selector',
  },
  FORMS: {
    NEWSLETTER: 'col',
    'CONTACT US': 'col-lg-10 offset-lg-1',
    'GATED CONTENT': 'col-lg-10 offset-lg-1',
  },
};

export const variants = {
  ACCORDION: 'ACCORDION',
  CALLOUTBAR: {
    FOOTER: 'FOOTER',
    STANDARD: 'STANDARD',
    LOGO: 'LOGO',
    UTILITY: 'UTILITY',
    CONTACT: 'CONTACT',
  },
  CARD: {
    ARTICLE: {
      FEATURED: 'FEATURED',
      CASESTUDY: 'CASESTUDY',
      CTA: 'CTA',
      NEWS: 'NEWS',
    },
  },
  CARDBLOCKS: {
    STAT: 'STAT',
    EXPERTISE: 'EXPERTISE',
    OFFERINGS: 'OFFERINGS',
    INFO: 'INFO',
    RESOURCES: 'RESOURCES',
  },
  HOMEBLOCKS: {
    ALERT: 'ALERT',
  },
  SUBNAV: 'SUBNAV',
  HYPERLINKS: 'HYPERLINKS',
};

export const separator = {
  SPACE: ' ',
  HYPHEN: '-',
  HYPHENSPACE: ' - ',
};

export const statIcons = {
  B: 'B',
  K: 'K',
  M: 'M',
  UP: 'UP',
  DOWN: 'DOWN',
  '%': '%',
};

export const statIconsData = {
  B: {
    id: 'billion',
    title: 'billion',
    description: '',
  },
  K: {
    id: 'thousand',
    title: 'thousand',
    description: '',
  },
  M: {
    id: 'million',
    title: 'million',
    description: '',
  },
  UP: {
    id: 'arrowup',
    title: 'increase',
    description: '',
  },
  DOWN: {
    id: 'arrowdown',
    title: 'decrease',
    description: '',
  },
  '%': {
    id: 'percent',
    title: 'percent',
    description: '',
  },
};

export const ctaVariants = {
  BUTTONLINK: 'ButtonLink',
  TEXTLINK: 'TextLink',
};

//TextLink, Hyperlink, Textlink with arrow
export const linkClasses = {
  BLUE: 'cta-text--blue',
  DARKBLUE: 'cta-text--darkblue',
  DARKGRAY: 'cta-text--darkgray',
  ORANGE: 'cta-text--orange',
  REVERSED: 'cta-text--reversed',
  REVERSEDBLUE: 'cta-text--blue-reversed',
  REVERSEDORANGE: 'cta-text--orange-reversed',
};

//TextButton
export const textBtnClasses = {
  BLUE: 'button-text--blue',
  DARKBLUE: 'button-text--darkblue',
  DARKGRAY: 'button-text--darkgray',
  ORANGE: 'button-text--orange',
  REVERSED: 'button-text--reversed',
  REVERSEDBLUE: 'button-text--blue-reversed',
  REVERSEDORANGE: 'button-text--orange-reversed',
};

//ButtonLink and Button
export const btnClasses = {
  PRIMARYBLUE: 'primary-button primary-button--blue',
  PRIMARYBLUEREVERSED: 'primary-button primary-button--blue-reversed',
  PRIMARYORANGE: 'primary-button primary-button--orange',
  PRIMARYORANGEREVERSED: 'primary-button primary-button--orange-reversed',
  SECONDARYBLUE: 'secondary-button secondary-button--blue',
  SECONDARYBLUEREVERSED: 'secondary-button secondary-button--blue-reversed',
  SECONDARYORANGE: 'secondary-button secondary-button--orange',
  SECONDARYORANGEREVERSED: 'secondary-button secondary-button--orange-reversed',
};

export const accordionItemsDisplay = {
  ALL: 'all',
  NONE: 'none',
  ANYONE: 'anyOne',
  MANY: 'many',
};

export const htmlElement = {
  DIV: 'DIV',
  SECTION: 'SECTION',
};
