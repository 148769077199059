import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './Accordion.scss';
import {
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import InsightsSearchResults from './InsightsSearchResults';
/**
 * @description to list search results
 * @param {object} props - component properties
 * @returns {node} -html
 */
const InsightSearch = ({ fields, t, InsightsSearchFilter, SocialCardInsights }) => {
  return (
    <InsightsSearchResults
      fields={fields}
      t={t}
      InsightsSearchFilter={InsightsSearchFilter}
      SocialCardInsights={SocialCardInsights}
    />
  );
};

InsightSearch.defaultProps = {
  fields: {},
  t: () => {},
  InsightSearchFilter: [],
  SocialCardInsights: [],
};

InsightSearch.propTypes = {
  fields: PropTypes.shape({}),
  t: PropTypes.func,
  InsightSearchFilter: PropTypes.arrayOf(PropTypes.shape()),
  SocialCardInsights: PropTypes.arrayOf(PropTypes.shape()),
};

// export default withRouter(
//   withPlaceholder([{ placeholder: 'jss-left', prop: 'InsightsSearchFilter' }])(
//     withTranslation()(withSitecoreContext()(InsightSearch))
//   )
// );
// export default withSitecoreContext()(
//   withPlaceholder([{ placeholder: 'jss-left', prop: 'InsightsSearchFilter' }])(
//     InsightSearch
//   )
// );
// export default withTranslation()(
//   withSitecoreContext()(
//     withPlaceholder([{ placeholder: 'jss-left', prop: 'InsightsSearchFilter' }])(
//       InsightSearch
//     )
//   )
// );

export default withTranslation()(
  withPlaceholder([{ placeholder: 'jss-search-left', prop: 'SocialCardInsights' }])(
    withRouter(withSitecoreContext()(InsightSearch))
  )
);
