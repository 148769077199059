/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getTitle } from '../../../utils/helperUtils';

/**
 * @description - Accordion Section component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const SearchAccordionSection = ({
  onClick,
  id,
  title,
  isOpen,
  focus,
  filterContent,
  t,
  setOpenSession,
}) => {
  const focusRef = useRef(null);

  const [expanded, setExpanded] = useState(true);

  /**
   * @description - to handle accordian click
   * @returns {undefined}
   */
  const handleClick = () => {
    onClick(id);
    setExpanded(!expanded);
  };

  /**
   * @description - to handle accordian toggle
   * @param {*} event - click eventി
   * @returns {undefined} - nothing
   */
  const handleToggleClick = (event) => {
    if (event.keyCode === 13) {
      onClick(id);
    }
  };

  useEffect(() => {
    if (focus && focusRef.current) {
      focusRef.current.focus();
    }
  });

  //adding intial filter state to array
  useEffect(() => {
    setOpenSession((prevState) => ({ ...prevState, ...{ [id]: isOpen } }));
  }, []);

  return (
    <div
      className={'accordion-block ' + (expanded ? 'active' : '')}
      role="listitem"
      id={`accordion${id}`}
    >
      <div
        className="icon"
        onKeyDown={handleToggleClick}
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-label={`toggle accordian ${t(getTitle(title))}`}
        aria-expanded={isOpen}
        ref={focus ? focusRef : null}
        onBlur={() => {
          focusRef.current = null;
        }}
      ></div>
      <h3>
        <div
          className={`accordion-title ${expanded ? 'show-tooltip' : ''}`}
          onClick={handleClick}
        >
          {title}
        </div>
      </h3>

      {filterContent && (
        <div className="accordion-content clearfix">{filterContent}</div>
      )}
    </div>
  );
};

SearchAccordionSection.defaultProps = {
  isOpen: '',
  id: '',
  title: { value: '' },
  content: { value: '' },
};
SearchAccordionSection.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  content: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func.isRequired,
};

export default withTranslation()(SearchAccordionSection);
