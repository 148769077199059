/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Link as JssLink,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

import {
  showString,
  checkHref,
  checkJSSObject,
  checkLinkType,
} from '../../../../utils/helperUtils';
import RightArrow from '../svgicons/RightArrow';

/**
 * @description - Button link.
 * @param {object} param0 - Input prams.
 * @returns {Node} - HTML node.
 */
const ButtonLink = ({
  ctaText,
  ctaUrl,
  ctaLabel,
  cssClass,
  externalLink,
  sitecoreContext,
  // cssArrowClass,
  arrow,
  handleButtonClick,
}) => {
  const isJSSObject = checkJSSObject(ctaUrl);
  const { pageEditing } = sitecoreContext;
  let linkVal = null;
  const isJSSText = ctaText && ctaText.hasOwnProperty('value');
  const isTextExists = isJSSText ? ctaText?.value : ctaText;
  const isHrefVlaue = isJSSObject ? checkHref(ctaUrl) : ctaUrl;
  const siteArr = sitecoreContext?.NoReffer?.sitename;
  const checkExternal = siteArr && ctaUrl?.linktype === 'external';
  let isValidExeLink = false;
  const isExternalLink = () => {
    if (checkExternal) {
      siteArr.forEach((eleme) => {
        if (ctaUrl.href.indexOf(eleme) >= 0) {
          isValidExeLink = true;
        }
      });
    }
    return isValidExeLink;
  };
  const isExtLink = isExternalLink();
  const textVal = (
    <Fragment>
      {isJSSText ? <Text field={ctaText} /> : showString(ctaText)}
      {arrow && (
        <div className="cta-icon" aria-hidden="true">
          <RightArrow />
        </div>
      )}
    </Fragment>
  );
  const propVal = {
    className: `button ${cssClass ? cssClass : ''}`,
    'aria-label': ctaLabel ? ctaLabel : ctaText?.value,
    title: ctaUrl?.value?.title ? ctaUrl?.value?.title : 'opens in a new window/tab',
  };
  if ((isJSSObject && checkLinkType(ctaUrl)) || (!isJSSObject && !externalLink)) {
    linkVal = (
      <Link
        to={isHrefVlaue}
        {...propVal}
        target={ctaUrl?.value?.target || ctaUrl?.target}
        onClick={handleButtonClick ? (e) => handleButtonClick(e) : null}
      >
        {textVal}
      </Link>
    );
  } else {
    linkVal =
      isExtLink || externalLink ? (
        <a
          href={isHrefVlaue}
          {...propVal}
          target={ctaUrl?.target ? ctaUrl?.target : '_blank'}
          rel="noopener"
          onClick={handleButtonClick ? (e) => handleButtonClick(e) : null}
        >
          {textVal}
        </a>
      ) : (
        <JssLink
          field={ctaUrl}
          {...propVal}
          editable={false}
          onClick={handleButtonClick ? (e) => handleButtonClick(e) : null}
        >
          {textVal}
        </JssLink>
      );
  }
  return pageEditing || (isHrefVlaue && isTextExists) ? linkVal : null;
};

ButtonLink.propTypes = {
  cssClass: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func,
  ctaText: PropTypes.shape({ value: PropTypes.string }).isRequired,
  ctaUrl: PropTypes.shape({ href: PropTypes.string }).isRequired,
  ctaLabel: PropTypes.string,
  externalLink: PropTypes.bool,
  arrow: PropTypes.bool,
  sitecoreContext: PropTypes.shape({
    pageEditing: PropTypes.bool,
  }),
  // cssArrowClass: PropTypes.string,
  t: PropTypes.func,
};

ButtonLink.defaultProps = {
  ctaText: '',
  ctaUrl: {},
  ctaLabel: '',
  handleButtonClick: null,
  cssClass: '',
  externalLink: false,
  sitecoreContext: {},
  arrow: false,
  // cssArrowClass: '',
  t: () => {},
};

export default withSitecoreContext()(ButtonLink);
