import React from 'react';
import PropTypes from 'prop-types';
import './InsightsBreadcrumb.scss';
import InsightsBreadcrumbLink from '../Breadcrumb/InsightsBreadcrumbLink';

/**
 * @description - Breadcrumb component.
 * @param {Object} fields - Input props.
 * @param {Object} brandName - Input string.
 * @returns {Node} - HTML template for Breadcrumb.
 */
const InsightsBreadcrumb = ({ fields }) => {
  const data = Array.isArray(fields?.BreadCrumbs)
    ? fields.BreadCrumbs
    : fields?.BreadCrumbs?.value;
  return (
    <div className="container-xl">
      <div
        className="insights-breadcrumb-wrapper"
        role="navigation"
        aria-label="Breadcrumb"
      >
        <ul className="insights-breadcrumb">
          {data?.map((item, index) => (
            <InsightsBreadcrumbLink
              key={item?.id}
              text={item?.name}
              url={item?.path}
              isLastItem={data?.length - 1 === index ? true : false}
              icon={item?.icon}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

InsightsBreadcrumb.defaultProps = {
  fields: {
    BreadCrumbs: [
      {
        name: '',
        path: '',
        icon: '',
      },
    ],
  },
};
InsightsBreadcrumb.propTypes = {
  fields: PropTypes.shape({
    BreadCrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        path: PropTypes.string,
        icon: PropTypes.string,
      })
    ),
  }),
};

export default InsightsBreadcrumb;
