import React from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import './InsightsList.scss';

const InsightsList = (props) => {
  return (
    <section className="insights-list-container">
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <Placeholder name="jss-carousel-left" rendering={props?.rendering} />
          </div>
          <div className="col-lg-6 col-md-12">
            <Placeholder name="jss-featured-right" rendering={props?.rendering} />
          </div>
        </div>
      </div>
    </section>
  );
};

InsightsList.defaultProps = {
  fields: {},
  rendering: {
    placeholders: {},
  },
};

InsightsList.propTypes = {
  fields: PropTypes.shape({}),
  rendering: PropTypes.shape({}),
};

export default InsightsList;
