import React from 'react';
import PropTypes from 'prop-types';
import './SocialCardInsights.scss';
//import ButtonLink from '../globals/link/ButtonLink';
//import { btnClasses } from '../../../utils/enums';
import ButtonLinkInsights from '../globals/link/ButtonLinkInsights';

const SocialCardInsights = (props) => {
  const {
    iconDesktop,
    title,
    content,
    ctaText,
    ctaLink,
    ctaBackgroundColor,
  } = props?.fields;
  const externalLinkType = ctaLink?.value?.linktype == 'external' ? true : false;
  return (
    <div className="social-card-insights">
      <div className="social-card-bg-gradient-one"> </div>
      <div className="social-card-insights-icon-circle">
        <img
          src={iconDesktop?.value?.src}
          className="social-card-insights-icon"
          alt={iconDesktop?.value?.alt}
        />
      </div>
      <div className="social-card-insights-content">
        <h3 className="social-card-insights-title">{title?.value}</h3>
        <p className="social-card-insights-text">{content?.value}</p>
        <ButtonLinkInsights
          ctaBackgroundColor={ctaBackgroundColor?.fields?.colorCode?.value}
          buttonRadius={ctaBackgroundColor?.fields?.buttonRadius?.value}
          mouseHoverColor={ctaBackgroundColor?.fields?.mouseHoverColor?.value}
          ctaFontColour={ctaBackgroundColor?.fields?.fontColor?.value}
          ctaText={ctaText?.value}
          ctaUrl={ctaLink?.value?.href}
          arrow
          externalLink={externalLinkType}
        />
        {/* <ButtonLink
          cssClass={btnClasses.PRIMARYORANGE}
          ctaText={ctaText?.value}
          ctaUrl={ctaLink?.value?.href}
          arrow
          externalLink={externalLinkType}
        /> */}
      </div>
      <div className="social-card-bg-gradient-two"> </div>
    </div>
  );
};

SocialCardInsights.defaultProps = {
  iconDesktop: {
    value: {
      src: '',
      alt: '',
      width: '',
      height: '',
    },
  },
  iconMobile: {
    value: {},
  },
  title: {
    value: '',
  },
  content: {
    value: '',
  },
  showCTAIcon: {
    value: '',
  },
  ctaIconPosition: '',
  ctaText: {
    value: '',
  },
  ctaIcon: {
    value: {},
  },
  ctaLink: {
    value: {
      href: '',
    },
  },
  ctaBackgroundColor: '',
  showUnderline: {
    value: '',
  },
};

SocialCardInsights.propTypes = {
  iconDesktop: PropTypes.shape({
    value: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
    }),
  }),
  iconMobile: PropTypes.shape({
    value: PropTypes.object,
  }),
  title: PropTypes.shape({
    value: PropTypes.string,
  }),
  content: PropTypes.shape({
    value: PropTypes.string,
  }),
  showCTAIcon: PropTypes.shape({
    value: PropTypes.bool,
  }),
  ctaIconPosition: PropTypes.any,
  ctaText: PropTypes.shape({
    value: PropTypes.string,
  }),
  ctaIcon: PropTypes.shape({
    value: PropTypes.object,
  }),
  ctaLink: PropTypes.shape({
    value: PropTypes.shape({
      href: PropTypes.string,
    }),
  }),
  ctaBackgroundColor: PropTypes.any,
  showUnderline: PropTypes.shape({
    value: PropTypes.bool,
  }),
};

export default SocialCardInsights;
