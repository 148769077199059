import React from 'react';
import PropTypes from 'prop-types';
import './InsightsSearchPagination.scss';
import LeftArrowChervon from '../globals/svgicons/LeftArrowChervon';
import RightArrowChervon from '../globals/svgicons/RightArrowChervon';
import { useMobile } from '../../../hooks/useMobile';

const InsightsSearchPagination = ({
  handlePageChange,
  totalRecords,
  pageLimit,
  selectedPage,
  //label,
}) => {
  const totalPages = Math.ceil(totalRecords / pageLimit);
  const isMobile = useMobile();

  const maxPagesToShow = !isMobile ? 8 : 4; // figma design and user story requested 8 pages to be shown

  const handleClick = (page) => {
    handlePageChange(page);
  };

  const handlePrevious = () => {
    if (selectedPage > 1) {
      handlePageChange(selectedPage - 1);
    }
  };

  const handleNext = () => {
    if (selectedPage < totalPages) {
      handlePageChange(selectedPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, selectedPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`page-number ${selectedPage === i ? 'active' : ''}`}
          onClick={() => handleClick(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="pagination-container">
      {/* <span className="pagination-label">{label}</span> */}
      <button
        className="pagination-button"
        onClick={handlePrevious}
        disabled={selectedPage === 1}
      >
        <LeftArrowChervon />
      </button>
      <div className="page-numbers">{renderPageNumbers()}</div>
      <button
        className="pagination-button"
        onClick={handleNext}
        disabled={selectedPage === totalPages}
      >
        <RightArrowChervon />
      </button>
    </div>
  );
};

InsightsSearchPagination.propTypes = {
  handlePageChange: PropTypes.func.isRequired,
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number.isRequired,
  selectedPage: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

export default InsightsSearchPagination;
