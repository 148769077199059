import React from 'react';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import './AuthorInsights.scss';

const AuthorInsights = ({ fields }) => {
  const { title, selectedAuthors } = fields;

  return (
    <div className="author-container">
      <div className="author-header">{title?.value}</div>

      {selectedAuthors.map((item) => (
        <div key={item?.id} className="author-body">
          <div className="author-image">
            <img
              src={mediaApi.updateImageUrl(item?.fields?.authorImage?.value?.src)}
              alt={item?.fields?.authorImage?.value?.alt}
              className="circle-image"
            />
          </div>

          <div className="author-info">
            <div className="author-about">
              <div className="author-details">
                <div className="author-name">{item?.fields?.authorName?.value}</div>

                <div className="author-designation">
                  {item?.fields?.designation?.value}
                </div>
              </div>

              <div className="author-saying">
                <div className="description-section">
                  {item?.fields?.description?.value}
                </div>
              </div>
            </div>

            {item?.fields?.ctaText?.value && (
              <div className="cta-container">
                <a
                  href={item?.fields?.ctaLink?.value?.href}
                  className={
                    'cta-link ' +
                    (item?.fields?.showUnderline?.value
                      ? 'text-decoration-underline'
                      : 'text-decoration-none')
                  }
                >
                  {item?.fields?.ctaText?.value}
                </a>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

AuthorInsights.defaultProps = {
  fields: {
    title: {
      value: '',
    },

    selectedAuthors: [
      {
        id: '',
        url: '',
        name: '',
        displayName: '',
        fields: {
          description: {
            value: '',
          },
          designation: {
            value: '',
          },
          authorImage: {
            value: {
              src: '',
              alt: '',
              width: '',
              height: '',
            },
          },
          authorName: {
            value: '',
          },
          showCTAIcon: {
            value: '',
          },
          ctaIconPosition: '',
          ctaText: {
            value: '',
          },
          ctaIcon: {
            value: {},
          },
          ctaLink: {
            value: {
              text: '',
              anchor: '',
              linktype: '',
              class: '',
              title: '',
              target: '',
              querystring: '',
              id: '',
              href: '',
            },
          },
          ctaBackgroundColor: {
            id: '',
            url: '',
            name: '',
            displayName: '',
            fields: {
              fontColor: {
                value: '',
              },
              buttonRadius: {
                value: '',
              },
              colorCode: {
                value: '',
              },
              style: {
                value: '',
              },
            },
          },
          showUnderline: {
            value: '',
          },
        },
      },
    ],
  },
};

AuthorInsights.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
    selectedAuthors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string,
        name: PropTypes.string,
        displayName: PropTypes.string,
        fields: PropTypes.shape({
          description: PropTypes.shape({
            value: PropTypes.string,
          }),
          designation: PropTypes.shape({
            value: PropTypes.string,
          }),
          authorImage: PropTypes.shape({
            value: PropTypes.shape({
              src: PropTypes.string,
              alt: PropTypes.string,
              width: PropTypes.string,
              height: PropTypes.string,
            }),
          }),
          authorName: PropTypes.shape({
            value: PropTypes.string,
          }),
          showCTAIcon: PropTypes.shape({
            value: PropTypes.string,
          }),
          ctaIconPosition: PropTypes.string,
          ctaText: PropTypes.shape({
            value: PropTypes.string,
          }),
          ctaIcon: PropTypes.shape({
            value: PropTypes.object,
          }),
          ctaLink: PropTypes.shape({
            value: PropTypes.shape({
              text: PropTypes.string,
              anchor: PropTypes.string,
              linktype: PropTypes.string,
              class: PropTypes.string,
              title: PropTypes.string,
              target: PropTypes.string,
              querystring: PropTypes.string,
              id: PropTypes.string,
              href: PropTypes.string,
            }),
          }),
          ctaBackgroundColor: PropTypes.shape({
            id: PropTypes.string,
            url: PropTypes.string,
            name: PropTypes.string,
            displayName: PropTypes.string,
            fields: PropTypes.shape({
              fontColor: PropTypes.shape({
                value: PropTypes.string,
              }),
              buttonRadius: PropTypes.shape({
                value: PropTypes.string,
              }),
              colorCode: PropTypes.shape({
                value: PropTypes.string,
              }),
              style: PropTypes.shape({
                value: PropTypes.string,
              }),
            }),
          }),
          showUnderline: PropTypes.shape({
            value: PropTypes.string,
          }),
        }),
      })
    ),
  }),
};

export default AuthorInsights;
