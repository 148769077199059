import React from 'react';

const SocialShareIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M17 3.66667C17 5.13943 15.8061 6.33333 14.3333 6.33333C13.2893 6.33333 12.3855 5.73341 11.9477 4.8595C11.7679 4.50058 11.6667 4.09544 11.6667 3.66667C11.6667 2.19391 12.8606 1 14.3333 1C15.8061 1 17 2.19391 17 3.66667Z"
      fill="white"
    />
    <path
      d="M17 14.3333C17 15.8061 15.8061 17 14.3333 17C12.8606 17 11.6667 15.8061 11.6667 14.3333C11.6667 13.9046 11.7679 13.4994 11.9477 13.1405C12.3855 12.2666 13.2893 11.6667 14.3333 11.6667C15.8061 11.6667 17 12.8606 17 14.3333Z"
      fill="white"
    />
    <path
      d="M6.33333 9C6.33333 9.42877 6.23214 9.83391 6.05233 10.1928C5.61452 11.0667 4.71065 11.6667 3.66667 11.6667C2.19391 11.6667 1 10.4728 1 9C1 7.52724 2.19391 6.33333 3.66667 6.33333C4.71065 6.33333 5.61452 6.93326 6.05233 7.80717C6.23214 8.16609 6.33333 8.57123 6.33333 9Z"
      fill="white"
    />
    <path
      d="M6.05233 10.1928C6.23214 9.83391 6.33333 9.42877 6.33333 9C6.33333 8.57123 6.23214 8.16609 6.05233 7.80717M6.05233 10.1928C5.61452 11.0667 4.71065 11.6667 3.66667 11.6667C2.19391 11.6667 1 10.4728 1 9C1 7.52724 2.19391 6.33333 3.66667 6.33333C4.71065 6.33333 5.61452 6.93326 6.05233 7.80717M6.05233 10.1928L11.9477 13.1405M6.05233 7.80717L11.9477 4.8595M11.9477 4.8595C12.3855 5.73341 13.2893 6.33333 14.3333 6.33333C15.8061 6.33333 17 5.13943 17 3.66667C17 2.19391 15.8061 1 14.3333 1C12.8606 1 11.6667 2.19391 11.6667 3.66667C11.6667 4.09544 11.7679 4.50058 11.9477 4.8595ZM11.9477 13.1405C11.7679 13.4994 11.6667 13.9046 11.6667 14.3333C11.6667 15.8061 12.8606 17 14.3333 17C15.8061 17 17 15.8061 17 14.3333C17 12.8606 15.8061 11.6667 14.3333 11.6667C13.2893 11.6667 12.3855 12.2666 11.9477 13.1405Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SocialShareIcon;
